import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, Select, Space, Spin, Tooltip, Typography, Tabs, TimePicker } from 'antd';
import { createPortal } from 'react-dom';
import { i18n } from '@/core';
import { Driver, Order, Truck, Plant } from '@/interfaces/brokrete';
import { OrderFeePanel } from '@components/OrderFeePanel';
import { useMutation } from '@apollo/client';
import { Products, DNDConfirantion, UnisignedProducts } from './components';
import { mergeData } from '@/shared/components/modal/DNDProductDeliveryConfirmation';
import { updateOrderMutation, UpdateOrderMutationResponse } from '@/view/modals/order/ChangeOrderTrucksModal/query';
import { TruckItem } from '@/view/modals/order/ChangeOrderTrucksModal/TruckItem';
import ICONS from '@/assets/images/icons';
import { DeleteFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { buildProductInput } from '@/shared/api/brokrete/helper';
import { array, number } from '@/libs';
import { omitBy, pick } from 'lodash';
import { useIsMounted } from '@/view/hooks/useIsMounted';
import { useDropdownButtonMenu, DropdownKeysEnum } from '@/view/modals/order/utils/dropdownButtonMenu';
import { DropdownButton } from '@/shared/components/navigation/DropdownButton';
import useTimeBetweenTrucksOptions from '@/view/hooks/useTimeBetweenTrucksOptions';
import { useGettext } from '@cranium/i18n';
import { OrderQuery, useOrderQuery } from './queries/orderWithConfigQuery.generated';
import { Delivery } from './components/DeliveryItems';
import moment, { Moment } from 'moment';
import { SAND_TYPES } from '@/view/pages/OrderCreatePage/constants';

type DeliveryItems = OrderQuery['order']['trucksDrivers'][0]['deliveryItems'];

const FormRow = styled.div<{ width?: string }>`
  display: grid;
  grid-template-columns: ${({ width }) => width || '150px'} 1fr;
  gap: 20px;
`;

const DriverTruckContainer = styled.div`
  display: grid;
  grid-template-columns: auto 150px 150px 150px 50px;
  gap: 12px;
  .ant-form-item-control-input-content {
    align-self: stretch;
  }
`;

const getInputProps = (form: FormState): InputProps => {
  const trucks = form.trucks?.map(v => v.value);
  const quantity = trucks ? array.sum(trucks) : undefined;
  const fixedQuantity = quantity ? number.round(quantity, { math: true, factor: 4 }) : undefined;
  const deliveries = form.trucks?.map(v => ({ id: v.truckId ?? '' }));
  return omitBy(
    {
      deliveryMethod: form.deliveryMethod,
      trucks,
      timeBetweenTrucks: form.timeBetweenTrucks,
      drivers: form.trucks.map(truck => ({ id: truck.driver?.value ?? '' })),
      deliveryTrucks: form.trucks.map(truck => ({ id: truck.truck?.value ?? '' })),
      deliveryPlants: form.trucks.map(truck => ({ id: truck.plant?.value ?? '' })),
      deliveries,
      quantity: fixedQuantity
    },
    v => v === undefined
  );
};

interface InputProps {
  deliveryMethod?: string;
  trucks?: Array<number>;
  deliveryPlants?: Array<{ id: string }>;
  deliveryTrucks?: Array<{ id: string }>;
  timeBetweenTrucks?: number;
  drivers?: Array<{ id: string }>;
  deliveries?: Array<{ id: string }>;
  quantity?: number;
}

interface FormState {
  deliveryMethod?: string;
  timeBetweenTrucks?: number;
  trucks: Array<{
    value: number;
    driver?: Driver & { value: string; label: string };
    truck?: Truck & { value: string; label: string };
    plant?: Plant & { value: string; label: string };
    truckId?: string;
    deliveryItems?: DeliveryItems;
    deliveryTime?: Moment | null;
  }>;
  unisignedProducts?: DeliveryItems;
}

interface Props {
  onChange: (order?: Order) => void;
  orderId: string;
}

export const ChangeOrderTrucksModal: React.FunctionComponent<Props> = ({ onChange, orderId }) => {
  const dropdownButtonMenu = useDropdownButtonMenu();
  const isMounted = useIsMounted();
  const [form] = Form.useForm<FormState>();
  const [feesLoading, setFeesLoading] = useState<boolean>(false);
  const [truckConfigView, setTruckConfigView] = useState<'general' | 'product'>('general');
  const [inputProps, setInputProps] = useState<InputProps>({});
  const [driverInputKey, setDriverInputKey] = useState('defaultKey');
  const feeInstance = OrderFeePanel.useInstance();
  const [touched, setTouched] = useState(false);
  const { data: { order, orderPreparatoryConfig } = {}, loading } = useOrderQuery({
    variables: { id: orderId },
    fetchPolicy: 'no-cache'
  });

  const [updateOrder, { loading: loadingMutation }] = useMutation<UpdateOrderMutationResponse>(updateOrderMutation);
  const canSetupTrucks = order?.product.permissions.trucks;
  const isMultyProduct = order?.product?.industry === 'related_products';
  const industry = order?.product.industry;
  const { options: timeBetweenTrucksOptions } = useTimeBetweenTrucksOptions({
    industry
  } as any);
  const { gettext } = useGettext();
  const isQuote = order?.status === 'quote';

  const orderPlantItem = useMemo(() => {
    if (order) {
      return { ...order.plant, value: order.plant.id, label: order.plant.name };
    }
    return undefined;
  }, [order]);
  // const isDisabled = () => {
  //   return !order?.product.permissions.trucks ||
  //     (order?.product.id === '5ecd2132-f4e3-4491-aaa9-2d4fc1683a8f' &&
  //       order?.sandType === SAND_TYPES.COLOUR &&
  //       order?.colorBag?.colorPercentageOption != null);
  // };
  // const onChangeTruckQuantity =(value:number)=>{
  //   const isMini = order?.product.id === '5ecd2132-f4e3-4491-aaa9-2d4fc1683a8f';
  // const isMini = product === '5ecd2132-f4e3-4491-aaa9-2d4fc1683a8f';

  //   const concentration = Number(order?.colorBag?.colorPercentageOption);
  //   const trucks = order?.trucks;

  //   if (!Number.isInteger(value)) return;

  //   let newValue = Math.min(Math.max(value, 1), 70);

  //   if (isMini && order?.sandType === SAND_TYPES.COLOUR && order?.colorBag?.colorPercentageOption != null) {
  //     if ([2, 6].includes(concentration)) {
  //       newValue = newValue % 2 === 0 ? newValue : newValue - 1;
  //     }
  //   }
  //   console.log("Value for Onchange :", newValue)

  // }

  useEffect(() => {
    const getDriverItem = (driver?: Driver | null) =>
      driver ? { ...driver, value: driver.id, label: driver.info.name } : undefined;
    const getTruckItem = (truck?: Truck | null) => (truck ? { ...truck, value: truck.id, label: truck.name } : undefined);
    const getPlantItem = (plant?: Plant) => (plant ? { ...plant, value: plant.id, label: plant.name } : undefined);
    if (order) {
      form.setFieldsValue({
        deliveryMethod: order.deliveryMethod.name,
        timeBetweenTrucks: order.timeBetweenTrucks,
        trucks: order.trucks.map((value, index) => ({
          value,
          driver: getDriverItem(order.trucksDrivers.find(({ id }) => +id === index)?.driver as any),
          truck: getTruckItem(order.trucksDrivers.find(({ id }) => +id === index)?.truck as any),
          plant: getPlantItem(order.trucksDrivers.find(({ id }) => +id === index)?.plant as any),
          truckId: order.trucksDrivers[index]?.truckId,
          deliveryItems: order?.trucksDrivers[index]?.deliveryItems,
          deliveryTime: order?.trucksDrivers[index]?.deliveryTime
            ? moment.tz(order?.trucksDrivers[index]?.deliveryTime, order?.timezone as string)
            : undefined
        })),
        unisignedProducts: []
      });
      setInputProps(getInputProps(form.getFieldsValue()));
    }
  }, [order]);

  const feeInput = pick(inputProps, ['deliveryMethod', 'trucks', 'timeBetweenTrucks']);

  return (
    <Modal
      title={<Typography className="semibold-16">{i18n.t('labels.schedule')}</Typography>}
      open={true}
      onCancel={() => onChange()}
      width={958}
      footer={
        <Space className="d-flex w-100 justify-content-between">
          <OrderFeePanel.TotalPrice feePanelInstance={feeInstance} />
          <DropdownButton
            dropdownMenu={dropdownButtonMenu}
            loading={loading || loadingMutation || feesLoading}
            disabled={!touched}
            onClick={async option => {
              if (feeInstance.requireActionConfirmation()) {
                return feeInstance.confirmAction();
              } else {
                form.getFieldValue('unisignedProducts');
                const unassigned = form.getFieldValue('unisignedProducts');
                if (Array.isArray(unassigned) && unassigned.find(item => item.quantity > 0)) {
                  setTruckConfigView('product');
                  await form.validateFields();
                }
                const values = form.getFieldsValue();
                const { quantity, ...input } = getInputProps(values);
                const product = buildProductInput(order as any as Order);
                if (order!.product.industry !== 'related_products') {
                  product.prices[0].quantity = quantity;
                }
                const deliveryItems = values.trucks.map(({ deliveryItems }) => {
                  return deliveryItems
                    ?.map(item => ({
                      productType: { id: item.productType.id },
                      quantity: item.quantity
                    }))
                    ?.filter(({ quantity }) => !!quantity);
                });

                const deliveryTimes = values.trucks.map(({ deliveryTime }) => {
                  return deliveryTime ? deliveryTime.format('YYYY-MM-DD HH:mm:ssZ') : null;
                });

                const orderVariables = {
                  id: orderId,
                  product,
                  ...input,
                  fees: feeInstance.getFee(),
                  deliveryItems: isMultyProduct ? deliveryItems : undefined,
                  trucks: isMultyProduct
                    ? deliveryItems.map(item => item?.reduce((res, it) => res + (it.quantity || 0), 0))
                    : input.trucks,
                  deliveryTimes: deliveryTimes
                };
                const variables = {
                  order: orderVariables,
                  quiet: option === DropdownKeysEnum.withoutNotification,
                  askToConfirm: option === DropdownKeysEnum.askApprove
                };
                const { data } = await updateOrder({ variables });
                if (data?.orderUpdate.success && isMounted.current) {
                  onChange(data.orderUpdate.order);
                }
              }
            }}
          />
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={(_, values) => {
            setTouched(true);
            setInputProps(getInputProps(values));
          }}
        >
          <DNDConfirantion setTouched={setTouched}>
            <FormRow>
              <div style={isMultyProduct ? { display: 'none' } : undefined}>
                <Form.Item
                  label={
                    <Typography.Text className="normal-12 text-uppercase gray-6">{gettext('DELIVERY METHOD')}</Typography.Text>
                  }
                  name={'deliveryMethod'}
                  normalize={item => item?.value}
                >
                  <Select
                    disabled={!canSetupTrucks}
                    labelInValue
                    options={orderPreparatoryConfig?.trucksConfigs.map(({ deliveryMethod: { name } }) => ({
                      value: name,
                      label: name
                    }))}
                  />
                </Form.Item>
              </div>
              <DriverTruckContainer>
                <Form.Item
                  label={
                    <Typography.Text className="normal-12 text-uppercase gray-6">
                      {gettext('TIME BETWEEN TRUCKS')}
                    </Typography.Text>
                  }
                  name={'timeBetweenTrucks'}
                  normalize={item => item?.value}
                >
                  <Select
                    disabled={(!canSetupTrucks && !isMultyProduct) || isQuote}
                    labelInValue
                    options={timeBetweenTrucksOptions}
                    onChange={item => {
                      const firstTruckTime = form.getFieldValue(['trucks', 0, 'deliveryTime']) as Moment;
                      form.setFieldValue(
                        'trucks',
                        (form.getFieldValue('trucks') as FormState['trucks']).map((truck, index) => {
                          return {
                            ...truck,
                            deliveryTime: moment.tz(firstTruckTime, order?.timezone as string).add(item.value * index, 'minutes')
                          };
                        })
                      );
                    }}
                  />
                </Form.Item>
              </DriverTruckContainer>
            </FormRow>
            <Form.List name="trucks">
              {(fields, { add, remove }) => {
                const trucksQuantityProps = (() => {
                  const baseProps = orderPreparatoryConfig?.trucksConfigs.find(
                    trucksConfig => trucksConfig.deliveryMethod.name === inputProps.deliveryMethod
                  )?.quantity;

                  if (!baseProps) return null;
                  // console.log("baseProps",baseProps)
                  const isMini = order?.product.id === '5ecd2132-f4e3-4491-aaa9-2d4fc1683a8f';
                  const isMaxi = !isMini;
                  const concentration = Number(order?.colorBag?.colorPercentageOption);
                  const isColourSand = order?.sandType === SAND_TYPES.COLOUR;
                  const isExposed = order?.sandType === SAND_TYPES.EXPOSED;

                  let min = baseProps.min;
                  let max = baseProps.max;
                  let step = baseProps.step;

                  if (isColourSand) {
                    if (isMini) {
                      if ([2, 6].includes(concentration)) {
                        min = 2;
                        max = 2;
                        step = 1;
                      } else if ([4, 8].includes(concentration)) {
                        min = 1;
                        max = 3;
                        step = 1;
                      }
                    } else if (isMaxi) {
                      if ([2, 6].includes(concentration)) {
                        min = 2;
                        max = 7;
                        step = 2;
                      } else if ([4, 8].includes(concentration)) {
                        min = 1;
                        max = 7;
                        step = 1;
                      }
                    }
                  }
                  if (isExposed) {
                    min = 1;
                    max = 7;
                    step = 1;
                  }

                  return {
                    ...baseProps,
                    min,
                    max,
                    step
                  };
                })();
                if (loading) return null;
                if (!trucksQuantityProps) {
                  return <Typography.Text type="danger">{i18n.t('labels.somethingWrong')}</Typography.Text>;
                }
                return (
                  <Space direction="vertical" className="w-100" size={12}>
                    {!!fields.length && (
                      <div>
                        <Header isMultyProduct={isMultyProduct}>
                          <Typography.Text className="bold-18 green-6">
                            {inputProps.quantity} {order?.units ?? ''}
                          </Typography.Text>

                          {isMultyProduct ? (
                            <Tabs
                              items={[
                                { key: 'general', label: gettext('General truck config') },
                                { key: 'product', label: gettext('Product config') }
                              ]}
                              activeKey={truckConfigView}
                              onChange={(vl: string) => setTruckConfigView(vl as 'product' | 'general')}
                            />
                          ) : null}
                        </Header>
                        <FormRow>
                          <MainInfoHead>
                            <Typography.Text className="normal-12 text-uppercase gray-6">{gettext('№')}</Typography.Text>
                            <Typography.Text className="normal-12 text-uppercase gray-6">{gettext('QTY')}</Typography.Text>
                          </MainInfoHead>
                          {truckConfigView === 'general' ? (
                            <DriverTruckContainer style={{ marginBottom: 0 }}>
                              <Typography.Text className="normal-12 text-uppercase gray-6">
                                {gettext('Delivery time')}
                              </Typography.Text>
                            </DriverTruckContainer>
                          ) : (
                            <ProductsHeader>
                              <Typography.Text className="normal-12 text-uppercase gray-6 mb-0">
                                {gettext('Truck`s Products')}
                              </Typography.Text>
                              <Typography.Text className="normal-12 text-uppercase gray-6 mb-0">
                                {gettext('Unassigned Products')}
                              </Typography.Text>
                            </ProductsHeader>
                          )}
                        </FormRow>
                      </div>
                    )}
                    <Wrapper>
                      <div>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <FormRow key={`field_${key}`} data-testid="trucks-row" style={{ marginBottom: '12px' }}>
                            <Form.Item {...restField} name={[name, 'truckId']} hidden>
                              <Input />
                            </Form.Item>
                            <TruckRow>
                              <Form.Item {...restField} name={[name, 'value']} className="mb-0">
                                <TruckItem
                                  index={index + 1}
                                  {...trucksQuantityProps}
                                  disabled={!canSetupTrucks}
                                  inputElememt={isMultyProduct ? <></> : undefined}
                                />
                              </Form.Item>
                              {isMultyProduct ? (
                                <Form.Item {...restField} name={[name, 'deliveryItems']} className="mb-0">
                                  <Delivery />
                                </Form.Item>
                              ) : null}
                            </TruckRow>
                            {truckConfigView === 'general' ? (
                              <div>
                                <DriverTruckContainer>
                                  <Form.Item {...restField} name={[name, 'deliveryTime']} className="mb-0">
                                    <TimePicker
                                      className="w-100"
                                      minuteStep={15}
                                      showSecond={false}
                                      format={'hh:mm A'}
                                      showNow={false}
                                      allowClear={false}
                                      use12Hours
                                      placeholder={gettext('Delivery time')}
                                    />
                                  </Form.Item>

                                  <Form.Item className="mb-0">
                                    <Tooltip title={gettext('Delete Truck')}>
                                      <Button
                                        type="text"
                                        style={{ width: 'fit-content' }}
                                        disabled={(!canSetupTrucks && !isMultyProduct) || fields.length === 1}
                                        icon={
                                          <DeleteFilled
                                            className={`${
                                              (!canSetupTrucks && !isMultyProduct) || fields.length === 1 ? '' : 'gray-7'
                                            }`}
                                          />
                                        }
                                        onClick={() => {
                                          if (isMultyProduct) {
                                            const value = form.getFieldValue(['trucks', name, 'deliveryItems']);
                                            if (!Array.isArray(value)) return remove(name);
                                            const newValue = value.reduce((res, item) => {
                                              return mergeData(item, res);
                                            }, form.getFieldValue('unisignedProducts'));
                                            form.setFields([
                                              {
                                                name: 'unisignedProducts',
                                                value: newValue,
                                                touched: true
                                              }
                                            ]);
                                          }

                                          remove(name);
                                          form.setFieldsValue({});
                                        }}
                                      />
                                    </Tooltip>
                                  </Form.Item>
                                </DriverTruckContainer>
                              </div>
                            ) : (
                              <ProductsFormItem {...restField} name={[name, 'deliveryItems']}>
                                <Products index={name} remove={() => remove(name)} />
                              </ProductsFormItem>
                            )}
                          </FormRow>
                        ))}
                      </div>
                      <div id={'uninusigned'}></div>
                    </Wrapper>
                    <div className="d-flex justify-content-end align-middle">
                      <Button
                        disabled={!canSetupTrucks && !isMultyProduct}
                        type="link"
                        icon={<img src={ICONS.icTruckContainer} alt="Add truck" className="mr-2" />}
                        className="p-0 h-auto"
                        onClick={() => {
                          const trucks = form.getFieldValue(['trucks']) as { deliveryTime: Moment }[];
                          const timeBetweenTrucks = (form.getFieldValue(['timeBetweenTrucks']) as number) || 0;
                          add({
                            value: trucksQuantityProps.min,
                            deliveryTime: moment
                              .tz(trucks[trucks.length - 1].deliveryTime, order?.timezone as string)
                              .add(timeBetweenTrucks, 'minutes')
                          });
                        }}
                      >
                        {gettext('Add truck')}
                      </Button>
                    </div>
                  </Space>
                );
              }}
            </Form.List>

            {document.getElementById('uninusigned')
              ? truckConfigView === 'product' &&
                createPortal(
                  <UnisignedFormItem
                    name={'unisignedProducts'}
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (value && Array.isArray(value) && value.filter(({ quantity }) => quantity).length) {
                            return Promise.reject(gettext('Please assign all Products to the trucks'));
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <UnisignedProducts errors={form.getFieldError(['unisignedProducts'])} />
                  </UnisignedFormItem>,
                  document.getElementById('uninusigned') as Element
                )
              : null}

            <OrderFeePanel orderId={orderId} input={feeInput} instance={feeInstance} onLoading={setFeesLoading} />
          </DNDConfirantion>
        </Form>
      </Spin>
    </Modal>
  );
};

const MainInfoHead = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 150px;
  > span {
    flex: 1;
  }
`;
const Header = styled.div<{ isMultyProduct?: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMultyProduct }) => (isMultyProduct ? '150px' : '2fr')} 1fr;
  gap: 24px;
  align-items: center;
  margin-bottom: 32px;
  border-bottom: ${({ isMultyProduct }) => (isMultyProduct ? '1px solid var(--gray-0)' : 'none')};
  .ant-tabs-nav {
    margin: 0 !important;
    :before {
      content: none;
    }
    .ant-tabs-nav-wrap {
      overflow: visible !important;
    }
    .ant-tabs-ink-bar-animated {
      bottom: -1px;
    }
    .ant-tabs-tab-btn {
      line-height: 26px;
    }
  }
  .ant-tabs-tab {
    padding-top: 0 !important;
    padding-bottom: 0px !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  > div {
    flex: 1;
  }
  #uninusigned {
    flex: 0;
    display: flex;
    flex-direction: column;
  }
`;

const ProductsHeader = styled.div`
  display: flex;
  align-items: center;

  > span {
    flex: 1;
    &:last-of-type {
      max-width: 168px;
      min-width: 168px;
      padding-left: 12px;
    }
  }
`;

const ProductsFormItem = styled(Form.Item)`
  flex: 2;
  margin-bottom: 0 !important;
  .ant-form-item-control-input {
    flex-direction: column !important;
  }
  .ant-form-item-control-input-content {
    flex: 1;
    width: 100%;
    display: flex;
  }
`;

const TruckRow = styled.div`
  display: flex;
  > div {
    flex: 1;
  }
  align-items: center;
  gap: 12px;
  .ant-form-item-control-input {
    min-height: 0;
  }
`;

const QTY = styled.div`
  > span:last-of-type {
    margin-left: 4px;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UnisignedFormItem = styled(Form.Item)`
  margin-bottom: 0 !important;
  flex: 1 !important;
  display: flex !important;
  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
  }
`;
